import { nanoid } from 'nanoid';
import { find, reduce } from 'lodash';
import { pulseQuestionTypes } from 'constants.js';
import { showSnackBarMessage } from 'utils/HelperFunctions';

export const mapApiToQuestionState = (questions) =>
    questions.map((question) => ({
        id: question?._id || question?.id,
        question: { name: question.title, error: false },
        questionType: question.type === 'MULTIPLE_CHOICE' || question.type === 'SINGLE_CHOICE' ? 'MCQ' : question.type,
        questionLabel:
            question?.type === 'MULTIPLE_CHOICE' || question?.type === 'SINGLE_CHOICE'
                ? 'Multiple choice'
                : find(pulseQuestionTypes, (type) => type.value === question.type)?.label,
        required: question?.required,
        multipleSelection: question?.type === 'MULTIPLE_CHOICE',
        options: question?.options
            ? question.options.map((option) => ({ name: option, error: false, id: nanoid() }))
            : [
                  { name: '', error: false, id: nanoid() },
                  { name: '', error: false, id: nanoid() },
              ],
    }));

const validatePulseAction = (message, setQuestionWarn, SetSnackbar, checkQuestion) => {
    if (checkQuestion) {
        setQuestionWarn({ isInvalid: true, message });
    } else {
        showSnackBarMessage(SetSnackbar, 'warning', message);
    }
};

const validateMCQ = (options, setQuestionWarn, SetSnackbar, checkQuestion) => {
    for (const option of options) {
        if (option.name === '') {
            validatePulseAction('Options cannot be empty', setQuestionWarn, SetSnackbar, checkQuestion);
            return false;
        } else if (
            find(options, (opt) => opt.id !== option.id && opt.name.toLowerCase() === option.name.toLowerCase())
        ) {
            validatePulseAction('Duplicate options found', setQuestionWarn, SetSnackbar, checkQuestion);
            return false;
        }
    }
    return true;
};

export const validatePulse = (
    questions,
    audience,
    setOpenQuestion,
    setQuestionWarn,
    SetSnackbar,
    checkQuestion,
    eNPS,
    scheduling
) => {
    let valid = true;
    if (!eNPS && questions?.length < 1) {
        validatePulseAction('Questions cannot be empty', setQuestionWarn, SetSnackbar, checkQuestion);
        return false;
    }
    if (scheduling.remindersEnabled && scheduling.reminderValue.length < 1) {
        showSnackBarMessage(SetSnackbar, 'warning', 'Pulse reminder is enabled but you have not set it up.');
        return false;
    }
    for (const [index, question] of questions.entries()) {
        if (question.question.name.trim() === '') {
            validatePulseAction('Question cannot be empty', setQuestionWarn, SetSnackbar, checkQuestion);
            valid = false;
            setOpenQuestion(index);
            break;
        } else if (question.questionType === 'MCQ') {
            valid = validateMCQ(question.options, setQuestionWarn, SetSnackbar, checkQuestion);
            if (!valid) {
                setOpenQuestion(index);
                break;
            }
        }
    }
    if (
        audience.userList.length === 0 &&
        audience.channelList.length === 0 &&
        audience.departmentList.length === 0 &&
        audience.teamsList.length === 0 &&
        audience.organization === false &&
        valid &&
        !checkQuestion
    ) {
        showSnackBarMessage(SetSnackbar, 'warning', 'Your audience is empty. To launch Pulse, update your selection.');
        valid = false;
    }
    return valid;
};
export const getRequestedListData = (users) =>
    reduce(
        users,
        ([currentChannelListData, userListData, userGroupsData], item) => {
            if (item.channel) {
                currentChannelListData.push({
                    _id: item.id,
                    channelID: item.channelID,
                    channelName: item.channelName,
                });
            } else if (item.groupID) {
                userGroupsData.push({
                    groupId: item.groupID,
                    groupName: item.groupName,
                });
            } else {
                userListData.push({
                    _id: item.id || item._id,
                    memberID: item.memberID,
                    userName: item.name || item.userName,
                });
            }
            return [currentChannelListData, userListData, userGroupsData];
        },
        [[], [], []]
    );
