import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckbox } from 'components';
import { AUDIENCE_DROPDOWN_TABS, SELECT_ALL } from '../constants';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import { find } from 'lodash';
import clsx from 'clsx';
import './style.scss';

const DropdownList = ({ data, selectedOptions, handleSelection, handleSelectionType, selectedTab }) => {
    const checkIsSelected = (item) => {
        // using this function to deal with inconsistencies of id and _id
        switch (handleSelectionType) {
            case AUDIENCE_DROPDOWN_TABS.user:
                return (
                    !!find(selectedOptions, (selectedUser) => selectedUser.id === item._id) ||
                    !!find(selectedOptions, (selectedUser) => selectedUser._id === item._id)
                );
            case AUDIENCE_DROPDOWN_TABS.channels:
                return !!find(selectedOptions, (selectedUser) => selectedUser.id === item.id);
            case AUDIENCE_DROPDOWN_TABS.departments:
                return (
                    !!find(selectedOptions, (selectedUser) => selectedUser._id === item.id) ||
                    !!find(selectedOptions, (selectedUser) => selectedUser.id === item.id)
                );
            case AUDIENCE_DROPDOWN_TABS.organization:
                return selectedOptions;
            default:
                return !!find(selectedOptions, (selectedUser) => selectedUser._id === item._id);
        }
    };

    return (
        <div className='dropdown-container-items'>
            <div>
                {data.length > 0 ? (
                    data.map((item) => {
                        let isSelected = checkIsSelected(item);
                        const displayValue = `${item.name || item.userName || item.channelName}${
                            selectedTab === 2 ? "'s team" : ''
                        }`;
                        return (
                            <EWButton
                                key={item.id || item._id}
                                width='100%'
                                primary={false}
                                buttonStyleClass={clsx({
                                    'dropdown-item-not-selected': !isSelected,
                                    'dropdown-item-is-selected': isSelected,
                                })}
                                onClick={() => handleSelection(item, handleSelectionType)}
                            >
                                <CustomCheckbox checked={isSelected} />
                                <EllipsisText
                                    text={displayValue}
                                    white
                                    maxWidth='350px'
                                    customClassName='dropdownlist-item'
                                />
                            </EWButton>
                        );
                    })
                ) : (
                    <p className='p-2 text-muted'>No results found</p>
                )}
            </div>
            {selectedTab === 4 && (
                <div className='org-tab-container'>
                    <InfoIcon className='info-icon' />
                    <p className='org-tab-text'>All users active at launch time will be included in your audience.</p>
                </div>
            )}
        </div>
    );
};

DropdownList.propTypes = {
    data: PropTypes.array,
    selectedOptions: PropTypes.array,
    handleSelection: PropTypes.func,
    handleSelectionType: PropTypes.string,
    selectedTab: PropTypes.number,
};

export default DropdownList;
