import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { frequencyType, futureEditFrequencyType, eNPSFrequency } from 'constants.js';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { EDIT_TYPE } from 'components/Dashboard/Content/PulseSurvey/constants';
import './style.scss';

const FrequencyType = ({
    defaultFrequencyType,
    selectedDay,
    selectedFrequencyType,
    isRollingPulse,
    eNPS,
    error,
    editType,
    disabled,
    isEditingRolling,
    customTemplate,
}) => {
    let frequencyList;
    let defaultFrequency;
    if (customTemplate) {
        frequencyList = frequencyType.filter((freq) => freq.value === 'one-time');
        defaultFrequency = defaultFrequencyType;
    } else if (eNPS) {
        frequencyList = eNPSFrequency;
        defaultFrequency = eNPSFrequency?.find((freq) => freq?.value === defaultFrequencyType.toLowerCase())?.label;
    } else if (editType === EDIT_TYPE.FUTURE) {
        frequencyList = futureEditFrequencyType;
        defaultFrequency = defaultFrequencyType;
    } else {
        frequencyList = frequencyType;
        defaultFrequency = defaultFrequencyType;
    }

    const [type, setType] = useState(defaultFrequency || frequencyList[0].label);

    const handleTypeChange = (currentType) => {
        setType(currentType.label);
        selectedFrequencyType({ name: 'frequencyType', value: currentType.value });
    };

    useEffect(() => {
        if (isRollingPulse) {
            handleTypeChange(frequencyList[0]);
        }
    }, [isRollingPulse]);

    return (
        <div className='frequency-template'>
            <div className='d-flex'>
                <CustomFilterDropdown // Time Input
                    title='FREQUENCY'
                    titleStyleClass='sub-heading'
                    dropdownStyleClass='margin-top-8'
                    filterOptions={frequencyList}
                    selectedName={<p className='text-capitalize font-size-14 mb-0'>{type}</p>}
                    handleSelection={(index) => handleTypeChange(frequencyList[index])}
                    dropDownID='timeInput'
                    singleSelect
                    buttonStyleClass='width-240 mr-5'
                    dropdownWidth={240}
                    disabled={disabled}
                    error={error}
                />
            </div>
            {isRollingPulse && !isEditingRolling && (
                <p className='disable-freq-note' data-testid='disable-freq-note'>
                    Note: Rolling Pulse are sent as a one-time pulse. Their frequency cannot be altered.
                </p>
            )}
        </div>
    );
};

FrequencyType.propTypes = {
    defaultFrequencyType: PropTypes.string,
    selectedDay: PropTypes.string,
    selectedFrequencyType: PropTypes.func,
    isRollingPulse: PropTypes.bool,
    eNPS: PropTypes.object,
    error: PropTypes.bool,
    editType: PropTypes.string,
    disabled: PropTypes.bool,
    isEditingRolling: PropTypes.bool,
    customTemplate: PropTypes.bool,
};

export default FrequencyType;
