import { getData, postData, patchData, postFileAndContent, deleteData, putData, postFile } from './apiCall';
import { format } from 'date-fns';
import { ANALYTICS_GROUPBY, ANALYTICS_VIEWS, COHORT_TYPE } from 'constants.js';
import queryString from 'query-string';

const DATE_FORMAT = 'yyyy-MM-dd';

export const getCoreValues = async () => {
    try {
        const res = await getData(`recognition/company-values`);
        return res.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const addCoreValues = async ({ apiData }) => {
    try {
        return await postData(`recognition/configure/company-values`, apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateCoreValues = async ({ apiData }) => {
    try {
        return await patchData(`recognition/configure/company-values`, apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateIsValuesRequired = async ({ apiData }) => {
    try {
        return await patchData(`workspace-config`, apiData);
    } catch (err) {
        throw new Error(err);
    }
};

export const saveNotification = async ({ apiData }) => {
    try {
        return await patchData(`recognition/configure/notifications`, apiData);
    } catch (err) {
        throw new Error(err);
    }
};

export const getLeaderboardData = async ({ queryKey: [_key, page, groupBy, dateRange] }) => {
    try {
        const res = await getData(`recognition/leaderboard?page=${page}&groupBy=${groupBy}&dateRange=${dateRange}`);
        return res.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAnalyticsPageTableData = async ({
    cohortId,
    startDate,
    queryType = 'user',
    page = 1,
    view = 'card',
    endDate = new Date(),
    sortKey = 'totalRecognitions',
    sortValue = -1,
    limit = 5,
    cohortType,
    searchText = '',
}) => {
    try {
        let queryURL =
            `analytics/tables?cohortId=${cohortId}` +
            `&startDate=${format(startDate, DATE_FORMAT)}&queryType=${queryType}&skip=${(page - 1) * 10}` +
            `&view=${view}&endDate=${format(
                endDate,
                DATE_FORMAT
            )}&sortKey=${sortKey}&sortValue=${sortValue}&limit=${limit}`;
        if (cohortType !== COHORT_TYPE.WORKSPACE) {
            queryURL += `&cohortType=${cohortType}`;
        }
        if (searchText.length) {
            queryURL += `&searchText=${searchText}`;
        }
        const { data } = await getData(queryURL);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAdminList = async () => {
    try {
        const { data } = await getData('user/admins');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAuditLogs = async ({
    pageParam = 1,
    queryKey: [_key, actionBy, search, settingName, date, start, end],
}) => {
    try {
        const queryParams = {
            page: pageParam,
            ...(actionBy && { actionBy }),
            ...(search && { search }),
            ...(settingName && { settingName }),
            ...(date && { date }),
            ...(date === 'custom' && { start: start, end: end }),
        };
        const queryStringParams = queryString.stringify(queryParams);
        const path = `logs?${queryStringParams}`;
        return await getData(path);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const modifyAdmins = async ({ apiData }) => {
    try {
        return await patchData('user/admin-status', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getWorkspaceConfig = async () => {
    try {
        const res = await getData('recognition/configure/workspace');
        return res.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const removeChannel = async ({ id }) => {
    try {
        return await deleteData(`channels/${id}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const addChannel = async ({ apiData }) => {
    try {
        return await postData('channels', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getProfile = async ({ queryKey: [_key, id] }) => {
    try {
        const response = await getData(`user/profile/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getUsers = async ({ pageParam = 1, queryKey: [_key, apiData] }) => {
    try {
        const { search, isActive = true, limit = 10, team = false, managerId, apiCase, excludeAdmin } = apiData;
        return await postData('organization/users', {
            skip: (pageParam - 1) * 10,
            limit,
            status: isActive,
            team,
            ...(managerId && { managerId }),
            ...(search && { search }),
            ...(apiCase && { case: apiCase }),
            ...(excludeAdmin && { excludeAdmin }),
        });
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPaginatedUsers = async ({
    queryKey: [
        _key,
        page = 1,
        isActive,
        managerId,
        manager,
        managerUnassigned,
        managerNameSort = null,
        nameSort = null,
        departmentSort = null,
        allowanceSort = null,
        statusSort = null,
        search = '',
    ],
}) => {
    try {
        const sortOrder = (value) => (value ? 1 : -1);
        const sortCheck = ({ sortType, sortValue }) =>
            typeof sortValue === 'boolean' && { sortBy: sortType, sortOrder: sortOrder(sortValue) };
        const response = await postData('organization/users', {
            skip: (page - 1) * 10,
            limit: 10,
            team: true,
            ...(search && { search }),
            ...(isActive && { status: isActive === 1 }),
            ...(managerId && { managerId }),
            ...(manager && { manager }),
            ...(managerUnassigned && { managerUnassigned }),
            ...sortCheck({ sortType: 'managerName', sortValue: managerNameSort }),
            ...sortCheck({ sortType: 'userName', sortValue: nameSort }),
            ...sortCheck({ sortType: 'dept', sortValue: departmentSort }),
            ...sortCheck({ sortType: 'monthlyAllowance', sortValue: allowanceSort }),
            ...sortCheck({ sortType: 'status', sortValue: statusSort }),
        });
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAllUsers = async () => {
    try {
        const { data } = await getData('organization/users/fetchAllUser');
        return data?.users || [];
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getManagerList = async () => {
    try {
        const response = await getData('organization/users/getManagerList');
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const modifyUser = async ({ apiData }) => {
    try {
        return await patchData('organization/users', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const changeApprover = async ({ apiData }) => {
    try {
        return await patchData('recognition/configure/workspace', apiData);
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const addCustomReward = async (apiData) => {
    try {
        return await postFileAndContent('rewards/addAndEditCustomRewards', apiData);
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getSubscriptionDetails = async () => {
    try {
        const { data } = await getData('payment/getPaymentDetails');
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getQuestionLibrary = async ({ queryKey: [_key, page, search, category] }) => {
    try {
        const queryParams = {
            page,
            ...(search && { search }),
            ...(category && { category }),
        };
        const queryStringParams = queryString.stringify(queryParams);
        const response = await getData(`pulse/questions/library?${queryStringParams}`);
        return response?.data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getDepartments = async ({ queryKey: [_key, page, limit = 10, search], pageParam = 1 }) => {
    try {
        const query = queryString.stringify({
            limit,
            page: page || pageParam,
            ...(search && { search }),
        });

        const url = `organization/departments?${query}`;
        const { data } = await getData(url);
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const addDepartment = async (apiData) => {
    try {
        return await postData('organization/departments', apiData);
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const updateDepartment = async (apiData) => {
    const { id, name, users } = apiData;
    try {
        return await patchData(`organization/departments/${id}`, { name, users });
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const deleteDepartment = async (departmentId) => {
    try {
        return await deleteData(`organization/departments/${departmentId}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getOrgCountries = async ({ queryKey: [_key, page, limit = 10, search] }) => {
    try {
        let url = `organization/countries?limit=${limit}`;
        if (page) url += `&page=${page}`;
        if (search) url += `&search=${search}`;
        const { data } = await getData(url);
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const addOrUpdateCountry = async (apiData) => {
    const { id, users } = apiData;
    try {
        return await putData(`organization/countries/${id}`, { users });
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const deleteCountry = async (countryCode) => {
    try {
        return await deleteData(`organization/countries/${countryCode}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getTemplates = async ({ queryKey: [_key, count = false] }) => {
    try {
        let url = 'pulse/templates';
        if (count) {
            url += `?countOnly=${count}`;
        }
        const { data } = await getData(url);
        return data;
    } catch (err) {
        throw new Error(err?.response?.message);
    }
};

export const fetchPulse = async ({ queryKey: [_key, apiData] }) => {
    try {
        const { skip = 0, limit = 10, search, filterBy, sortBy, sortDir } = apiData;
        let path = `pulse/list?skip=${skip}&`;
        if (limit) path += `limit=${limit}&`;
        if (search) path += `search=${search}&`;
        if (filterBy) path += `filterBy=${filterBy}&`;
        if (sortBy) path += `sortBy=${sortBy}&`;
        if (sortDir) path += `sortDir=${sortDir}`;
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const fetchChannels = async (url) => {
    try {
        const response = await getData(url);
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const deletePulse = async ({ pulseId }) => {
    try {
        return await deleteData(`pulse/${pulseId}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const pausePulse = async ({ pulseId, pause }) => {
    try {
        return await getData(`api/v2/dashboard/pulse/${pulseId}/pauseOrResume/?pause=${pause}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const endPulse = async ({ pulseId, instanceType }) => {
    try {
        const data = {};
        // Check if instanceType is provided and add it to the data object
        if (instanceType) {
            data['stop'] = instanceType;
        }
        return await patchData(`pulse/end/${pulseId}`, data);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const generateAIPulse = async (apiData) => {
    try {
        const { data } = await postData('ai/generate-pulse', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.error);
    }
};

export const sendPulseReminder = async (pulseId) => {
    try {
        const { data } = await getData(`pulse/${pulseId}/send-reminders`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const createPulse = async ({ apiData }) => {
    try {
        const { data } = await postData('pulse', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const editPulse = async ({ pulseID, apiData }) => {
    try {
        const { data } = await patchData(`pulse/${pulseID}`, apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPulse = async ({ pulseId, editType = 'ongoing', isOngoing }) => {
    try {
        const { data } = isOngoing
            ? await getData(`pulse/${pulseId}/?editType=${editType}`)
            : await getData(`pulse/${pulseId}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPulseResponses = async ({ queryKey: [_key, responseId] }) => {
    try {
        const response = await getData(`pulse/answerData/${responseId}`);
        return response.data.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const submitPulseResponses = async ({ apiData }) => {
    try {
        const { data } = await postData('pulse/responseSubmit', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPulseTemplateQuestions = async ({ templateId }) => {
    try {
        const { data } = await getData(`pulse/templates/questions/${templateId}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getOrgDataForPulse = async ({ queryKey: [_key, pulseID] }) => {
    try {
        const paramQuery = pulseID ? `?pulseIssueId=${pulseID}` : '';
        const { data } = await getData(`pulse/organizational-data${paramQuery}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getCategorizedENPSData = async ({ type, pulseIssueId }) => {
    try {
        const paramQuery = pulseIssueId ? `&pulseIssueId=${pulseIssueId}` : '';
        const { data } = await getData(`pulse/categorized-data?type=${type}${paramQuery}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const promoteRecognition = async ({ pulseIssueId }) => {
    try {
        const { data } = await getData(`pulse/promote-recognition/${pulseIssueId}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRewardsCatalog = async ({ apiData }) => {
    try {
        return await postData('rewards/catalog', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateVoucherStatus = async ({ apiData }) => {
    try {
        return await postData('rewards/statusUpdate', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const placeOrder = async ({ apiData }) => {
    try {
        const { data } = await postData('redemption/placeOrder', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const fetchRedeemHistory = async ({ queryKey: [_key, apiData] }) => {
    try {
        const { page = 1, limit = 10, search, startDate, endDate, productId = [] } = apiData;
        const queryParams = {
            limit,
            skip: (page - 1) * 10,
            ...(search.length > 0 && { search }),
            ...(startDate && { dateStart: startDate }),
            ...(endDate && { dateEnd: endDate }),
            ...(productId.length > 0 && { productId }),
        };
        const { data } = await postData('redemption/user', queryParams);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const deleteReward = async (rewardId) => {
    try {
        return await deleteData(`xoxoday/deleteCustomReward?voucherId=${rewardId}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getVoucherDetails = async ({ id, initiationStage }) => {
    try {
        const url = `rewards/details?productId=${id}&initiationStage=${initiationStage}`;
        const { data } = await getData(url);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRewardStatsAnalytics = async ({ queryKey: [_key, cohort, startDate, endDate] }) => {
    try {
        let path = 'analytics/stats?';
        if (cohort?._id && cohort?.type && ['manager', 'department'].includes(cohort.type))
            path += `cohortId=${cohort._id}&cohortType=${cohort.type}&`;
        if (startDate) path += `startDate=${format(startDate, DATE_FORMAT)}&`;
        if (endDate) path += `endDate=${format(endDate, DATE_FORMAT)}`;
        const { data } = await getData(path);
        return data?.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getCompanyValuesStatsAnalytics = async ({ queryKey: [_key, cohort, startDate, endDate] }) => {
    try {
        let path = 'analytics/values?';
        if (cohort?._id && cohort?.type && ['manager', 'department'].includes(cohort.type))
            path += `cohortId=${cohort._id}&cohortType=${cohort.type}&`;
        if (startDate) {
            path += `startDate=${format(startDate, DATE_FORMAT)}&`;
        }
        if (endDate) path += `endDate=${format(endDate, DATE_FORMAT)}`;

        const { data } = await getData(path);
        return data.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRandRLineChartData = async ({ queryKey: [_key, cohort, startDate, endDate, groupBy, view] }) => {
    try {
        let path = 'analytics/linechart?';
        if (cohort?._id && cohort?.type && ['manager', 'department'].includes(cohort.type))
            path += `cohortId=${cohort._id}&cohortType=${cohort.type}&`;
        if (startDate) path += `startDate=${format(startDate, DATE_FORMAT)}&`;
        if (endDate) path += `endDate=${format(endDate, DATE_FORMAT)}&`;
        if (groupBy && [ANALYTICS_GROUPBY.RECOGNITIONS, ANALYTICS_GROUPBY.REDEMPTIONS].includes(groupBy))
            path += `groupBy=${groupBy}&`;
        if (view && ANALYTICS_VIEWS.includes(view)) path += `view=${view}`;

        const data = await getData(path);
        return data.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAnalyticsCohortData = async () => {
    try {
        const { data } = await getData('analytics/cohorts');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getMissingSpecialDateUserCount = async () => {
    try {
        const { data } = await getData('celebrations/user-count-with-no-special-date?onlyCount=true');
        return data || 0;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateCelebrationSettings = async ({ apiData }) => {
    try {
        return await patchData('celebrations/settings', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getGiftValues = async () => {
    try {
        const { data } = await getData('celebrations/gift-values');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getEmployeesWithoutDates = async ({ queryKey: [_key, page, search, filter] }) => {
    try {
        const queryParams = {
            page: page || 1,
            ...(search.length > 0 && { search: search }),
            ...(filter.length > 0 && { filter: filter }),
        };

        const queryStringParams = queryString.stringify(queryParams);
        const path = `celebrations/user-count-with-no-special-date?${queryStringParams}`;
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getCelebrationConfigs = async () => {
    try {
        const res = await getData('celebrations/configurations');
        return res?.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const specialDateDM = async (users, filter) => {
    try {
        const payload = {
            users,
            filter: filter,
        };
        return await postData('celebrations/dm-user-for-dates', payload);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getGlobalSettings = async () => {
    try {
        const { data } = await getData('onboarding/global');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const configureGlobalSettings = async (apiData) => {
    try {
        return await postData('onboarding/global', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateGlobalSettings = async (apiData) => {
    try {
        return await patchData('onboarding/global', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const registerOnboardingModules = async (modules) => {
    try {
        const apiData = { modules };
        await postData('onboarding/modules', apiData);
        return true;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getOnboardingModules = async () => {
    try {
        const { data } = await getData('onboarding/modules');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getOrganizationsList = async () => {
    try {
        const { data } = await getData('workspace/list');
        return data.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const workspaceSelection = async (apiData) => {
    try {
        const { workspaceId, userId } = apiData;
        const { data } = await getData(`workspace/select?workspaceId=${workspaceId}&userId=${userId}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const addNewWorkspace = async () => {
    try {
        const { data } = await postData('workspace/addNew');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const setupRnR = async (apiData) => {
    try {
        return await postData('onboarding/setupRandRFlow', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getIntegratedChannelsList = async () => {
    try {
        const { data } = await getData('channels/integrated');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPublicChannelsList = async () => {
    try {
        const { data } = await getData('channels/public');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateRedemptionSettings = async ({ apiData }) => {
    try {
        const apiDataLength = Object.keys(apiData).length;
        if (apiDataLength > 0) {
            await patchData('recognition/configure/points', apiData);
        }
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const updateOrganisationWideRedemption = async ({ apiData }) => {
    try {
        const apiDataLength = Object.keys(apiData).length;
        if (apiDataLength > 0) {
            await patchData('redemption/configuration', apiData);
        }
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getAudienceCount = async ({
    withImages,
    userGroups,
    channels,
    requestedUsers,
    departmentList,
    teamsList,
    excludedUsers,
    organization,
}) => {
    try {
        const query = queryString.stringify({
            withImages,
            organization,
            ...(userGroups?.length && { userGroups: userGroups.join(',') }),
            ...(channels?.length && { channels: channels.join(',') }),
            ...(departmentList?.length && { departments: departmentList.join(',') }),
            ...(teamsList?.length && { teams: teamsList.join(',') }),
            ...(requestedUsers?.length && { requestedUsers: requestedUsers.join(',') }),
            ...(excludedUsers?.length && { excludedUsers: excludedUsers.join(',') }),
        });

        const path = `users/audience-count-and-images?${query}`;
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPointsConfig = async () => {
    try {
        const res = await getData('recognition/configure/points ');
        return res.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
export const getOrganisationWideRedemptionConfig = async () => {
    try {
        const res = await getData('redemption/configuration');
        return res.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRecognitonTypes = async () => {
    try {
        const res = await getData('recognition/recognition-types');
        return res.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const setupWalletAutoReload = async (apiData) => {
    try {
        return await patchData('wallet/setupAutoReload', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getCustomerBillingPortalLink = async () => {
    try {
        return await getData('payment/billingPortal');
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getUserDetails = async () => {
    try {
        const { data } = await getData('user/profile');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getWorkspaceDetails = async () => {
    try {
        const { data } = await getData('workspace/profile');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const bulkUploadUsers = async (apiData) => {
    try {
        const { data } = await postFile('users/bulkCSVUpdate', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getOrderDetails = async (orderId) => {
    try {
        const { data } = await postData('redemption/orderDetails', { orderId });
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const fetchTransactions = async ({ queryKey: [_key, page, search, filter] }) => {
    const apiData = {
        page,
        groupBy: filter.groupBy,
        sortBy: filter.sortBy,
        order: filter.order,
        ...(search && { search }),
        ...(filter?.dateRange && { dateRange: filter.dateRange }),
        ...(filter?.values?.length > 0 && { values: filter.values }),
        ...(filter?.recognitionType?.length > 0 && { recognitionTypes: filter.recognitionType }),
        ...(filter?.status?.length > 0 && { status: filter.status }),
    };
    try {
        const { data } = await postData('recognition/activities/me', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAllRedemptionActivities = async ({ queryKey: [_key, search, filter] }) => {
    const apiData = {
        skip: filter.skip,
        limit: 10,
        sortBy: filter.sortBy,
        order: filter.order,
        ...(search && { name: search }),
        ...(filter?.dateStart && { dateStart: filter.dateStart }),
        ...(filter?.dateEnd && { dateEnd: filter.dateEnd }),
        ...(filter?.productId && filter.productId.length > 0 && { productId: filter.productId }),
    };

    try {
        const response = await postData('redemption/allActivity', apiData);
        return response;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAllRecognitionActivities = async ({ queryKey: [_key, transactionState, filter] }) => {
    const apidata = {
        page: filter.page,
        transactionState: transactionState,
        sortBy: filter.sortBy,
        order: filter.order,
        ...(filter?.search && { search: filter.search }),
        ...(filter?.dateRange && { dateRange: filter.dateRange }),
        ...(filter?.values?.length > 0 && { values: filter.values }),
        ...(filter?.managerIds?.length > 0 && { manager: filter.managerIds }),
        ...(filter?.recognitionType?.length > 0 && { recognitionTypes: filter.recognitionType }),
        ...(filter?.approver?.length > 0 && { approvers: filter.approver }),
    };
    try {
        const response = await postData('recognition/activities/all', apidata);
        return response;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAllBonusActivities = async ({ queryKey: [_key, filter] }) => {
    const transformedFilter = {
        ...filter,
        ...(filter.dateRange !== null && typeof filter?.dateRange === 'object'
            ? {
                  'dateRange[start]': filter.dateRange.start,
                  'dateRange[end]': filter.dateRange.end,
              }
            : {}),
    };

    if (filter.dateRange !== null && typeof filter?.dateRange === 'object') {
        delete transformedFilter.dateRange;
    }

    const tempFilter = Object.fromEntries(
        Object.entries(transformedFilter).filter(([_, value]) => value != null && value !== '')
    );
    const params = queryString.stringify(tempFilter);
    try {
        const { data } = await getData(`bonus/activities/all?${params}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getMyBonusActivities = async ({ queryKey: [_key, filter] }) => {
    const transformedFilter = {
        ...filter,
        ...(typeof filter?.dateRange === 'object' && filter.dateRange !== null
            ? {
                  'dateRange[start]': filter.dateRange.start,
                  'dateRange[end]': filter.dateRange.end,
              }
            : {}),
    };

    if (typeof filter?.dateRange === 'object' && filter.dateRange !== null) {
        delete transformedFilter.dateRange;
    }
    const tempFilter = Object.fromEntries(
        Object.entries(transformedFilter).filter(([_, value]) => value != null && value !== '')
    );
    const params = queryString.stringify(tempFilter);
    try {
        const { data } = await getData(`bonus/activities/me?${params}`);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPulseDetailsData = async ({ queryKey: [_key, pulseId, pulseIssueId, excludeUserDetails] }) => {
    const query = queryString.stringify({
        pulseIssueId,
        excludeUserDetails,
    });

    try {
        const baseUrl = `pulse/${pulseId}`;
        const url = query ? `${baseUrl}?${query}` : baseUrl;
        const { data: pulseDetailsData } = await getData(url);
        return pulseDetailsData;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPulseResponseData = async ({ queryKey: [_key, pulseId, pulseIssueId] }) => {
    const query = pulseIssueId ? `?pulseIssueId=${pulseIssueId}` : '';
    try {
        const { data: pulseResponsesData } = await getData(`pulse/${pulseId}/responses${query}`);
        return pulseResponsesData;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPulseSummaryData = async ({ queryKey: [_key, pulseId, pulseIssueId] }) => {
    const query = pulseIssueId ? `?pulseIssueId=${pulseIssueId}` : '';
    try {
        const { data: pulseSummaryData } = await getData(`pulse/summary/${pulseId}${query}`);
        return pulseSummaryData;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getTasksData = async ({ pageParam = 1, queryKey: [_key] }) => {
    try {
        const path = `users/tasks?page=${pageParam}`;
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const setTaskStatus = async (payload) => {
    try {
        const path = 'recognition';
        const { data } = await patchData(path, payload);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const enhanceMessage = async (message) => {
    const payload = {
        message: message,
    };

    try {
        const path = 'ai/enhance-text';
        const { data } = await postData(path, payload);
        return data.message;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const rateConversion = async ({ apiData }) => {
    try {
        return await postData('currency-conversions', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
